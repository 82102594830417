.div{
    text-align: center;
}

.list{
}
.nonlist{
    list-style: none;
}
.listitem{
    text-align: left;
}
.title{

}

@media screen and (max-width: 768px){
    .div{
        width: 75vw;
    }

}