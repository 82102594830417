.video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.videocontainer {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.body {
    overflow: hidden;
}

.videoinfo{
    color: white;
    font-size: 10px;
    z-index: 999;
}

.invisible {
    visibility: hidden;
}

.menuitems{
    display: none;
    border: 5px white solid;
}
.menuitems ul{
    display: none;
}


@media screen and (max-width: 768px) {
    .videocontainer{
        height: 100vh;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .videocontainer p{
        display: none;
    }
    .menuitems{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .video{
        display: none;
    }
}