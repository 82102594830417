
.title{
    text-align: center;
    color: var(--title-color);
    font-family: var(--kaushan-script);
    font-size: var(--title-size);
}

.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}