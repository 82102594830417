@import "../../../config/variables.css";
.dropul{
    list-style: none;
    padding: 0px;
    position: absolute;
    left: auto;
    right: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--menu-color);
    z-index: 1000;
}

@media screen and (max-width: 768px)  {
    .dropul{
        text-align: center;
        position: relative;
        list-style: none;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
