.welcome{
    position: fixed;
    top: 50px; /* height of menubalk */
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.welcomeText{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.6);
}

.invisible{
    visibility: hidden;
}

.logoSign{
    max-width: 356px;
    max-height: 226px;
    display: flex;
    
}

.info{
    font-size: small;
}

@media screen and (max-width: 768px){
    .welcome{
        /*visibility: hidden;*/
    }
    .welcomeText{

    }
}
