
@import "../../config/variables.css";
.main {
    background-color: var(--menu-color);
    /*width: 100vw;*/
    height: var(--menu-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    z-index: 9999;
    position: sticky;
    top: 0;
}

.headertextcontainer{
    height: var(--menu-height);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.icons{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.invisible {
    visibility: hidden;
}

.invisible img{
    height: 0px;
}

.logo img {
    max-height: var(--menu-height);
}
.svglogo img {
    max-height: var(--menu-height);
}

.logo {
    padding-right: 5px;
    padding-left: 5px;
    border-right: 1px solid black;
    max-height: var(--menu-height);
}

.svglogo {
    padding-right: 5px;
    padding-left: 5px;
    border-right: 1px solid black;
    max-height: var(--menu-height);
}






.headertext{
    font-family: var(--menu-header-font-family);
    font-size: var(--menu-header-font-size);
    color: var(--menu-header-color);
}

.mobilemenus{
    display: none;
}

@media screen and (max-width: 768px){
    .main{
        justify-content: flex-start;
    }
    .menus{
        display: none;
    }
    .mobilemenus{
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
    }

    .mobilemenus img{
        max-height: var(--menu-height);
        max-width: var(--menu-height);
        margin: 0;
    }



    .headerinvisible {
        display: none;
    }

}


