
@import "../../../config/variables.css";

.logo {
    max-height: var(--menu-height);
}

.invisible{
    display: none;
}



.ul {
    display: flex;
    flex-direction: row;
    list-style: none;
}

/*.menus {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*}*/


@media screen and (max-width: 768px) {
    .ul{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        list-style: none;
        background-color: var(--menu-color);
        margin: 0;
        padding: 0px;
    }


}