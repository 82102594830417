@import "../../config/variables.css";
.foto{
    max-width: 90%;
    margin: 5%;
    border: var(--menu-color) 5px solid;
    border-radius: 5px;
}

.video{
    max-width: 90%;
    margin: 5%;
}

.title{
    color: var(--title-color);
    font-family: var(--kaushan-script);
    font-size: 36px;
}

.videocontainer, .fotocontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

