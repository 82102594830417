.title{
    color: var(--title-color);
    font-family: var(--kaushan-script);
    font-size: var(--title-size);
}

.main{
    text-align: center;
    height: calc(100vh - var(--menu-height));
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.ul{
    list-style: none;
}

.vlag{
    height: 200px;
    width: auto;
}

.pictures{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
    .pictures{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
}