body {
  background-color: lightgrey;
  background-image: url("resources/camper-background-pattern.jpg");
  background-size: 200px;
  background-blend-mode: lighten;
}

.footer{
  position: fixed;
  bottom: 0;
  height: 20px;
  width: 100%;
  /*border: #0F2F9C 5px solid;*/
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.footer p{
  font-size: 12px;
  opacity: 30%;
  padding-right: 10px;
}