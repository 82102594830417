@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');
:root{
    --title-color: #ECA842;
    --title-size: 48px;
    --kaushan-script: 'Kaushan Script', cursive;
    --menu-color: #0F2F9C;
    --menu-textcolor-selected: rgba(198, 198, 198, 0.4);
    --menu-textcolor-unselected: white;
    --menu-height: 50px;
    --menu-header-font-family: 'Tangerine', cursive;
    --menu-header-font-size: 48px;
    --menu-header-color: white;

}