
@import "../../../config/variables.css";

.unselected{
    color: var(--menu-textcolor-unselected);
    text-decoration: none;
}

.selected{
    font-weight: bolder;
    color: var(--menu-textcolor-selected) ;
}


.li {
    padding-left: 10px;
    padding-right: 5px;
    border-right: 1px solid black;
}

.submenu{

}


@media screen and (max-width: 768px) {

    .li {
        border: none;
        padding: 0px;
        background-color: var(--menu-color);
    }

    .submenu{
        display: inherit;
    }

    .childen {
       display: inherit;
    }
}