.main{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
}

.textcontainer p{
    margin: 0px;
}

.main a{
    text-decoration: none;
    color: black;
    word-break: break-word;
}
.logo{
    padding-right: 10px;
    height: 42px;
    width: 42px;
}
.text{

}