.title{
    color: var(--title-color);
    font-family: var(--kaushan-script);
    font-size: var(--title-size);
}

.main{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}
.centerpiece{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 630px;
    width: 100%;
}
.desktopinfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.desktopinfo a{
    /*text-decoration: none;*/
    /*color: black;*/
    /*border: #0F2F9C 5px solid;*/
}

.wehl{
    max-height: 630px;
    max-width: 100%;
}
.mobileinfo{
    display: none;
}


@media screen and (max-width: 768px) {
    .desktopinfo{
        display: none;
    }

    .wehl{
        max-width: 50%;
    }
    .mobileinfo{
        display: flex;
        flex-direction: column;
    }
    .centerpiece{
        height: auto;
    }



}