.main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 30px;
}

.left{
    flex-direction: row-reverse;

}

.right{
}

@media screen and (max-width: 768px){
    .main{
        flex-direction: column;
    }
    .left{
        flex-direction: column;
    }

}