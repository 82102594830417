.main{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.image{
    width: 25%;
    height: auto;
}

.title{
    color: var(--title-color);
    font-family: var(--kaushan-script);
    font-size: var(--title-size);
}

@media screen and (max-width: 768px){
    .desktop{
        display: none;
    }
    .fotocontainer{
        display: flex;
        justify-content: center;
    }
    .image{
        width: 50%;
    }
}